body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

p {
  font-family: 'Inter', sans-serif;
  letter-spacing: 1.2px;
  font-weight: normal;
  color: var(--black);
}

.overflow-hidden {
  overflow: hidden!important;
}

span {
  letter-spacing: 1.2px;
}

h1 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 900;
}

h2 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 900;
}

h3 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 900;
}

h4 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 900;
}

h5 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 900;
}

a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  letter-spacing: 1.2px;
  font-weight: normal;
}

a:hover {
  text-decoration: none;
}

li {
  list-style-type: none;
  padding: 0px;
  margin-top: 5px;
  font-family: 'Inter', sans-serif;
  letter-spacing: 1.2px;
  font-weight: normal;
}

ul {
  padding: 0px;
}

.black-color {
  color: var(--black)!important;
}

.white-color {
  color: var(--white)!important;
}

.true-white-color {
  color: var(--true-white)!important;
}

.blue-color {
  color: var(--blue)!important;
}

.yellow-color {
  color: var(--yellow)!important;
}

.purple-color {
  color: var(--purple)!important;
}

.white-background {
  background-color: var(--white)!important;
}

.blue-background {
  background-color: var(--blue)!important;
}

.purple-background {
  background-color: var(--purple)!important;
}

.flag {
  padding: 5px 25px;
  background-color: var(--purple);
  color: var(--white);
  font-weight: 200;
  border-radius: 8px 8px 8px 0px;
}

.separator {
  width: 300px;
  border: 7px solid var(--purple);
  border-radius: 20px;
  margin: auto;
}

:root {
  --black: #00111A;
  --white: #F2F4F5;
  --true-white: #FFFFFF;
  --blue: #005682;
  --yellow: #FFE234;
  --purple: #C39FCD;
}