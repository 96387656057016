.header {
    background-color: var(--white);
}

.header__offcanvas {
    /* background-color: var(--blue); */
}

.header__ul ul li button{
    color: var(--black) !important;
    transition: 0.1s;
    font-weight: 200; 
}

.header__ul ul li button:hover{
    color: var(--blue) !important;
    font-weight: 400 !important;
    border-style: none none solid none;
    border-width: 4px;
    /* border-color: var(--black); */
    transition: 0.1s;
}

.header__toggler {
    border: none;
}

/* .header__toggler:focus {
    border: 1px solid;
} */

.header__toggler svg {
    color: var(--black) !important;
}
.navbar img{
    cursor: pointer;
}

.nav-item {
    font-size: 18px;
    margin: 8px 6px;
}

.dropdown-menu-navbar {
    background-color: transparent;
    border: none;
}

.dropdown-menu-navbar li {
    font-size: 18px;
}

/* .dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--purple);
} */

.navbar-toggler{
    border: none;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 var(--blue);
    text-decoration: none;
}

.offcanvas-header img {
    cursor: pointer;
}