.mainButton__blue .cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}

.mainButton__blue .cta:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  border-radius: 50px;
  background: VAR(--blue);
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.mainButton__blue .cta span {
  position: relative;
  font-size: 18px;
  /* padding: 0px 0px 0px 5px; */
  background-color: transparent;
  /* border-radius: 20px; */
  font-family: 'Inter', sans-serif;
  letter-spacing: 1.2px;
  font-weight: 500;
  /* color: var(--black); */
}

.mainButton__blue .cta svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  /* stroke: var(--black); */
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
  background-color: transparent;
}

.mainButton__blue .cta:hover:before {
  width: 100%;
  background: var(--purple);
}

.mainButton__blue .cta:hover svg {
  transform: translateX(0);
}

.mainButton__blue .cta:active {
  transform: scale(0.95);
}
