.team__main {
    overflow-x: hidden;
}
/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .team__icon {
        padding: 10px 50px;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
}

@media (min-width: 1200px) {
}