.blog__header {
    text-align: center;
}

.blog__subtitle {
    padding: 10px 380px;
}

.blog__subtitle p {
    background-color: var(--purple);
    color: var(--white);
    font-weight: 400;
    border-radius: 6px;
}

.blog__categories {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    list-style: none;
    margin-block: 0;
    overflow-x: auto;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
}

.blog__categories li {
    border-radius: 1px solid var(--blue);
    padding: 5px 10px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 300;
    border: 1px solid var(--blue);    
}

.blog__categories li:hover {
    background-color: var(--blue);
    color: var(--white);
    transition: all 0.3s ease;

}

.blog__card-image {
    position: relative;
}

.blog__card-image img {
    border-radius: 6px;
}

.blog__card-image__content {
    position: absolute;
    bottom: 0px;
    background-color: var(--white);
    padding: 10px;
    opacity: 0.8;
    border-radius: 0px 0px 6px 6px;
}

.blog__arrow {
    margin-bottom: 5px;
}

.blog__link-underline {
    text-decoration: underline;
}

.blog__card-content p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 20px;
}