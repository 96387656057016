.aboutUs__img {
    width: 75%;
    border-radius: 10px;
}

.aboutUs__purpleCard {
    background-color: var(--purple);
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0px;
    text-align: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
    .aboutUs__img {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .aboutUs__img {
        display: none;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
}

@media (min-width: 1200px) {
}