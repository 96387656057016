.treatment__benefits {
    padding: 0px!important;
}

.treatment__detail__icon {
    color: var(--blue);
}

.treatment__detail__icon-menu {
    float: right;
}