.tratments .text-center.p-0 img {
    border-radius: 6px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .tratments__img {
        display: none;
    }
    .tratments .text-center.p-0 img {
        max-width: 75%;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .tratments__img {
        display: none;
    }
    .tratments .text-center.p-0 img {
        max-width: 75%;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .tratments__imgLg {
        display: none;
    }
    .tratments .text-center.p-0 img {
        max-width: 95%;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .tratments__imgLg {
        display: none;
    }
}

@media (min-width: 1200px) {
    .tratments__imgLg {
        display: none;
    }
}