.reviews__card {
    background-color: var(--true-white);
    border-radius: 10px;
}

.reviews__avatar {
    width: 75px!important;
    height: 75px!important;
    margin-left: auto;
    margin-right: auto;
}

.reviews__star {
    color: var(--yellow);
}