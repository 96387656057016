.footer__logo {
  height: 50px;
}

.footer__ul {
  padding: 0px;
}

.footer__ul li {
  list-style: none;
  margin: 8px 0px;
}

.footer__ul li a {
  transition: 0.3s;
}

.footer__ul li a:hover {
  font-size: 18px;
  font-weight: bold !important;
  transition: 0.3s;
  border-width: 2px;
  border-color: var(--purple);
  border-style: none none solid none;
}

.footer__social .footer__icon {
    color: var(--white);
}

.footer__social li {
    list-style: none;
    margin: 0px;
}

.footer__social li a svg{
    transition: 0.3s;
}

.footer__social li a svg:hover {
    transform: scale(1.5);
    transition: 0.3s;
    border-width: 2px;
}
/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .footer .row {
        text-align: center;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .footer .row {
        text-align: center;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .footer .row {
        text-align: center;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
}

@media (min-width: 1200px) {
}
