.hr-blue {
    border-top: 8px solid var(--blue);
    border-radius: 50px;
    width: 50%;
    margin: auto;
}

.compromise {
    border-radius: 10px;
}

.compromise__icon {
    background-color: var(--purple);
    border-radius: 20px;
    width: 80px;
    height: 80px;
    margin: auto;
    padding: 20px;
}

.compromise svg path {
    stroke: var(--white)!important;
    color: var(--white)!important;
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .compromise p {
        padding: 0px 200px;
    }
}

@media (min-width: 1200px) {
    .compromise p {
        padding: 0px 400px;
    }
}