.post__route {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.post__route li {
    color: var(--blue);
    margin-left: 5px;
}

.post__route a {
    color: var(--blue);
    text-decoration: none;
    transition: all 0.3s ease;
}

.post__container img {
    border-radius: 6px;
}

.post__credits {
    display: block;
    float: right;
}

.post__content ol li {
    list-style-type: upper-roman;
    margin-left: 3rem;
}

.post__content ul li {
    list-style-type: circle;
    margin-left: 3rem;
}

.post__sideBar {
    padding: 10px;
    background-color: #ebebeb;
    border-radius: 6px;
}

.post__sideBar__list li {
    margin: 1rem 0;
}

.post__sideBar__list li a {
    list-style-type: none;
    margin-bottom: 1rem;
    color: var(--purple);
    transition: all 0.3s ease;
}

.post__sideBar__list li a:hover {
    font-weight: 600;
    color: var(--blue);
    transition: all 0.3s ease;
}