.post__editor .row {
    padding: 0px 10px;
}

.post__editor div {
    padding: 0;
}

.post__editor-preview {
    padding: 10px;
    border: 1px solid var(--blue);
    border-radius: 6px;
}

.post__editor-preview ol li {
    list-style-type: upper-roman;
    margin-left: 3rem;
}

.post__editor-preview ul li {
    list-style-type: circle;
    margin-left: 3rem;
}

.post__editor-img {
    border-radius: 6px;
}