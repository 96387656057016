/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    .industry__banner-lg {
        display: none!important;
    }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {
    .industry__banner-lg {
        display: none!important;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {
    .industry__banner-sm {
        display: none!important;
    }

    .carousel-caption {
        padding-bottom: 0 !important;
    }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {
    .industry__banner-sm {
        display: none!important;
    }
    
    .carousel__treatmentsDec {
        padding-right: 200px;
    }

    .carousel-caption {
        padding-bottom: 2.25rem !important;
    }
}

@media (min-width: 1200px) {
    .industry__banner-sm {
        display: none!important;
    }

    .carousel__treatmentsDec {
        padding-right: 300px;
    }

    .carousel-caption {
        padding-bottom: 3.25rem !important;
    }
}
